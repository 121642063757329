import { useEffect } from "react"
import "vanilla-cookieconsent"
import "vanilla-cookieconsent/dist/cookieconsent.css"

/**
 * Embed and activate the cookie consent widget.
 * https://github.com/orestbida/cookieconsent
 */
const CookieConsent = () => {
  useEffect(() => {
    if (!window.initCookieConsent) {
      return
    }

    const cookieConsent = window.initCookieConsent()

    cookieConsent.run({
      autorun: true,
      current_lang: "en",
      autoclear_cookies: true,
      page_scripts: true,
      languages: {
        en: {
          consent_modal: {
            title: "Before you keep reading.",
            description:
              'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. <a aria-label="Cookie policy" class="cc-link" href="/privacy-policy#cookies">Read more</a>',
            primary_btn: {
              text: "Accept",
              role: "accept_all",
            },
            secondary_btn: {
              text: "Settings",
              role: "settings",
            },
          },
          settings_modal: {
            title: "Cookie preferences",
            save_settings_btn: "Save settings",
            accept_all_btn: "Accept all",
            reject_all_btn: "Reject all",
            blocks: [
              {
                title: "Cookie usage",
                description:
                  "We use cookies to ensure the basic functionality of the website, and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.",
              },
              {
                title: "Strictly necessary cookies",
                description:
                  "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true,
                },
              },
              {
                title: "Analytics cookies",
                description:
                  "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
                toggle: {
                  value: "analytics",
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: "More information",
                description:
                  'For more information on cookies and your choices, please read our <a class="cc-link" href="/privacy-policy#cookies">Privacy Policy</a>.',
              },
            ],
          },
        },
      },
    })
  }, [])

  return null
}

export default CookieConsent
