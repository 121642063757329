const React = require("react")
const CookieConsent = require("./src/components/CookieConsent").default

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.wrapPageElement = ({ element }) => {
  return (
    <>
      <CookieConsent />
      {element}
    </>
  )
}
